<template>
  <div
    v-if="(!useAuthStore().isLoggedIn || !(useAuthStore().user?.is_operator || useAuthStore().isUserOrganization)) && props.youtube"
    :class="{ 'video-help--small': useBreakpoint.smaller('md').value }"
    class="video-help"
  >
    <el-tooltip
      effect="dark"
      :content="$t('VideoHelp.tooltip')"
    >
      <el-button
        :type="props.type"
        :size="props.size"
        circle
        @click="isOpen = true"
      >
        <span class="tw-sr-only">
          {{ $t('VideoHelp.help') }} - {{ props.title }}
        </span>
        <icon-interpreting />
      </el-button>
    </el-tooltip>

    <el-dialog
      v-model="isOpen"
      :title="`${$t('VideoHelp.tooltip')} - ${props.title}`"
      width="750px"
      top="10vh"
      class="el-dialog--spaceless-body"
      append-to-body
    >
      <template #header>
        <span>
          <icon-interpreting /> {{ $t('VideoHelp.help') }} - {{ props.title }}
        </span>
      </template>
      <div class="video-wrapper">
        <iframe
          :title="props.title"
          width="560"
          height="315"
          :src="`https://www.youtube-nocookie.com/embed/${props.youtube}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
        <div class="video-placeholder tw-flex tw-items-center tw-justify-center">
          <icon-loading />
        </div>
      </div>

      <template #footer>
        <div class="tw-text-center tw-w-full">
          <el-button
            size="small"
            @click="isOpen = false"
          >
            {{ $t('close') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  youtube: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'default',
  },
  size: {
    type: String,
    default: 'small',
  },
});

// Refs
const isOpen = ref(false);
</script>
